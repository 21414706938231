import { useState } from 'react'

const API_URL = process.env.REACT_APP_AI_API_URL + '/api/v1/user-registration/'
const USER_INFO = {
  isAuthenticated: false,
  success: false,
  isLoading: false,
}
/**
 * authenticate the user
 */
export default function UserRegistration() {
  const [userInfo, setUserInfo] = useState(USER_INFO)

  const registrateUser = async (name, lastName, email, phone, cedula, role, company, position, password, notifications, age, aducationLevel, laguage) => {
    const requestInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        lastName: lastName,
        email: email,
        password: password,
        phone: phone,
        cedula: cedula,
        role: role,
        position: position,
        company: company,
        notifications: notifications,
        language: laguage,
        educationLevel: aducationLevel,
      })
    };
    setUserInfo({ ...USER_INFO, isLoading: true })
    await fetch(`${API_URL}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setUserInfo({
            success: true,
            isLoading: false
          })
        } else {
          setUserInfo({
            success: false,
            message: result.message,
            isLoading: false
          })
        }
      })
      .catch(error => {

        setUserInfo(c => ({ ...c, error }))
      })
  }
  return { ...userInfo, registrateUser }
}
