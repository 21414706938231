import React, { useEffect, useState, useCallback } from 'react'
import '../css/speechRecognition.css'
import axios from 'axios'
import { Backdrop, Button, stepButtonClasses } from '@mui/material'
import Room from './Room'
import useConfig from '../hooks/useConfig'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Replay from '@mui/icons-material/ReplayOutlined'
import Play from '@mui/icons-material/PlayArrowOutlined'
import Pause from '@mui/icons-material/PauseOutlined'
import Lottie from 'react-lottie'
import MessageInterface from './messageInterface'
import animationData from '../media/icons/loading.json'
import '../css/voiceInterface.css'
import Chip from '@mui/material/Chip'
import LoadingMessages from './loadingMessages'
import ProgressBar from './progressBar'
import UseLessons from '../hooks/useLessons'
import { Howl } from 'howler'
import QuizPage from './Quiz'
import { Quiz } from '@mui/icons-material'

const ErrorSistema = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/Asistente%20de%20cursos%20buenos%20das_x264.mp4';

// const accountStateAudio = ''
const sdk = require("microsoft-cognitiveservices-speech-sdk")
const speechConfig = sdk.SpeechConfig.fromSubscription("e467e2dc3c9b4ec2887248735495d109", "eastus")
speechConfig.speechRecognitionLanguage = "es-MX"
let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput()
let sound 

function Dictaphone({ updateSate, listeningOn, stopSpeechListening, id, auth_token, sessionId, conversational, initialPrompt, isFirstPrompt}) {

    const [joined, setJoined] = useState(false)
    const [responseType, setResponseType] = useState()
    const [nextLesson, setNextLesson] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [accountStateFlow, setAccountStateFlow] = useState(false)
    const [cedula, setCedula] = useState()
    const [image, setImage] = useState()
    const [conversation, setConversation] = useState([])
    const [conversationToDisplay, setConversationToDisplay] = useState([])
    const [voiceMessage, setVoiceMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [answers, setAnswers] = useState()
    const [IssuedId, setIssuedId] = useState()
    const [updateContent, setUpdateContent] = useState(true)
    const [endAudio, setEndAudio] = useState(false)
    const [showNextButton, setShowNextButton] = useState(false)
    const [qResult, setQResult] = useState()
    
    

    const {
        isLoadingCall,
        token,
        getToken,
        // roomName,
    } = useConfig()

    const { 
        isLoading,
        lessonNumber,
        moduleTitle,
        lessonTitle,
        moduleNumber,
        lessonSummary,
        response_type,
        content,
        audioUrl,
        getNextLesson,
    } = UseLessons()


   

    if (isFirstPrompt || sound === undefined){
        sound= new Howl({ 
            html5: true,
            src: audioUrl ? audioUrl:'https://codeskulptor-demos.commondatastorage.googleapis.com/pang/arrow.mp3',
            onend: function() {
                setEndAudio(true)
              } })
    }

    const setSound= (link) => {
        if (sound){
        sound.stop();
        sound._sounds[0]._node.src = link;
        sound._sounds[0]._node.load();
        sound.play() 
        }
    }

    // eslint-disable-next-line no-unreachable
    const textToSpeech = useCallback(async (text) => {
        var content = {
            text: text,
        }
        const BASE_URL = process.env.REACT_APP_AI_API_URL
        await axios.post(BASE_URL + '/api/v1/text-to-speech/', content)
            .then(function (response) {
                setPlaying(true)
                setLoading(false)
                var data = response.data
                if (data) {
                    reproduceAudio(data.link)
                }
            })
            .catch(function (error) {
                //sistem error
                setLoading(false)
            })
    }, [reproduceAudio, setPlaying, setLoading])

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig)

    function replayResponse() {
        if (sound) {
            sound.stop()
            sound.play()
            setPlaying(true)
        }
    }

    const cleanTimeOut = () => {
        if (nextLesson){
        clearTimeout(nextLesson)
        }
    }

    document.addEventListener("pointerdown", cleanTimeOut)

    function forwardLesson(payload){
        sound?.stop()
        setVoiceMessage(false)
        var pastLesson = lessonNumber ? { 'past_lesson_number': lessonNumber }: qResult
        setLoading(true)
        getNextLesson(auth_token, qResult ? qResult : pastLesson)
        setUpdateContent(true)
        setEndAudio(false)
        setNextLesson(null)
        setQResult(null)
    }


    const setQuizResult = (obj) => {
        setQResult(obj)
    }

    useEffect(() => {
        if (!isLoading && updateContent && (audioUrl || content)){
            setResponseType(response_type)
            setLoading(false)
            setConversationToDisplay(conversationToDisplay.concat([
                { "role": "assistant", "content": moduleNumber ? 'Módulo - '+ moduleNumber +' '+ moduleTitle +'\n'+ lessonTitle : lessonTitle }, 
                { "role": "assistant", "content": lessonSummary },
            ]))
            setConversation(conversation.concat([
                { "role": "assistant", "content": 'Módulo - '+ moduleNumber + moduleTitle +'\n\n'+ lessonTitle }, 
                { "role": "assistant", "content": lessonSummary },
            ]))
            setEndAudio(false)
            setSound(audioUrl)
            setPlaying(true)
            setVoiceMessage(true)
            setUpdateContent(false)
            setResponseType('lesson')
        }

        if (responseType ==='lesson' && !nextLesson && endAudio===true && response_type ==='lesson'){
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setNextLesson( setTimeout(() => {
                forwardLesson(qResult)
            }, 5000));
        }



        recognizer.recognizing = (s, e) => {
        }

        recognizer.recognized = (s, e) => {
            if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                // console.log(`RECOGNIZED: Text=${e.result.text}`)
                stopSpeechListening()
                if (accountStateFlow) {
                    setCedula(e.result.text)
                } else {
                    setLoading(true)
                    setVoiceMessage(false)
                    request(e.result.text)
                }

            }
            else if (e.result.reason === sdk.ResultReason.NoMatch) {
                // console.log("NOMATCH: Speech could not be recognized.")
            }
        }

        recognizer.canceled = (s, e) => {
            recognizer.stopContinuousRecognitionAsync()
        }

        recognizer.sessionStopped = (s, e) => {
            recognizer.stopContinuousRecognitionAsync()
        }


        const request = async (text) => {
            // if (trialCount()) {
            const requestInfo = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': auth_token
                },
                body: JSON.stringify({ 'prompt': conversation.concat([{ "role": "user", "content": text }]), "id": id, sessionId: sessionId, date: new Date().toLocaleString() })
            };
            const BASE_URL = process.env.REACT_APP_AI_API_URL
            fetch(BASE_URL + '/api/v1/conversation-prompt/', requestInfo)
                .then(res => res.json())
                .then(result => {
                    updateSate ({endAudio: false})
                    setNextLesson(false)
                    stopSpeechListening()
                    var response = result.answer
                    var audioLink = result.audioUrl
                    var action = result.action
                    var imageUrl = result.imageUrl
                    if (action === 'video') {
                        getToken(sessionId)
                        setJoined(true)
                    }
                    if (action === 'estado de cuenta') {
                        setAccountStateFlow(true)
                        textToSpeech(response)
                    }
                    setImage(imageUrl)
                    setResponseType(response.type)
                    setConversation(conversation.concat([
                        { "role": "user", "content": text },
                        { "role": "assistant", "content": response?.answer ? '{"answer":"'+response?.answer + '","summary":"' + response?.summary + '", "type":"'+ response?.type +'", "title":"'+ response?.title +'"}': response }]))
                    
                    setConversationToDisplay(conversationToDisplay.concat([
                        { "role": "user", "content": response?.type === 'lesson' ? response?.title : text },
                        { "role": "assistant", "content": response?.type === 'lesson' ? response?.summary : response?.answer}]))

                    if (sound) { sound.stop() }
                    if (audioLink !== '') {
                        setSound(audioLink)
                        setPlaying(true)
                    }
                    setVoiceMessage(true)
                    setLoading(false)

                })
                .catch(function (error) {
                    updateSate({ 'video': ErrorSistema });
                    setLoading(false)
                    return false
                })
            // }
        }

        if (isFirstPrompt){
            updateSate({isFirstPrompt: false})
            getNextLesson(auth_token,{})
            setUpdateContent(true)
        }

        if (listeningOn) {
            // checkPermissions()
            recognizer.recognizeOnceAsync()
        } else {
            recognizer.stopContinuousRecognitionAsync()
            stopSpeechListening()
        }
        if (listeningOn && sound) {
            sound.stop()
        }
        
        
        return () => {
            // This is the cleanup function
            document.removeEventListener("pointerdown", cleanTimeOut);
        };


    }, [auth_token, conversation, qResult, id, listeningOn, recognizer, sessionId, playing, stopSpeechListening, updateSate, endAudio, nextLesson, updateContent, voiceMessage, setUpdateContent, setSound, isLoading, audioUrl, setNextLesson, responseType, isFirstPrompt, conversationToDisplay, moduleTitle, lessonTitle, lessonSummary, lessonNumber, getNextLesson, accountStateFlow, getToken, textToSpeech, setNextLesson, content,setQResult])


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
            className: 'loading'
        }
    }

    if (loading || isLoadingCall || isLoading) {

        return (
            <Backdrop
                sx={{
                    color: 'White', backgroundColor: '#ffffffa0',
                    zIndex: 15000, padding: '20px', backdropFilter: 'blur(5px)',
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'center'
                }}
                open={loading || isLoadingCall || isLoading}>
                <Lottie
                    options={defaultOptions}
                    height={200}
                    width={200}
                    isClickToPauseDisabled={true}
                />
                <LoadingMessages />


            </Backdrop>
        )
    } else if ((voiceMessage && !joined && conversational)) {
        
        let playControl = <Play className='pause-audio' onClick={() => playAudio()} />
        let pauseControl = <Pause className='pause-audio' onClick={() => pauseAudio()} />

        const closeLabel = <span>X</span >
        return (
            <React.Fragment>
                <Chip className="close-audio" label={closeLabel} variant="outlined" onClick={() => closeMessageInterface()} style={{ zIndex: 10000 }}> </Chip>
                <Backdrop
                    sx={{ color: 'black', backgroundColor: '#faf5ea', zIndex: 1000, padding: '20px', alignItems: 'flex-start' }}
                    open={voiceMessage} >

                    <p alt='Crecer Labs' style={{ position: 'absolute', fontSize:'13px', zIndex: 11 }}> Powered by CrecerLab</p>
                    <Box className='buttons-actions'>
                        <Replay className='replay-audio' onClick={() => replayResponse()} ></Replay>
                        {playing ? pauseControl : playControl}
                    </Box>
                    <Stack spacing={0} className='msg-container' justifyContent="center"
                        alignItems="center">
                        {/* <Avatar alt="Lisa" src={Lisa} style={{height:'150px',width:'150px'}}/> */}
                        {/* { <Lottie
                            options={SoundAnimationOptions}
                            height={40}
                            width={200}
                            speed={0.5}
                            isPaused={!playing || endAudio}
                            isClickToPauseDisabled={true}
                        />} */}
                        
                        { response_type !== 'quiz' && <MessageInterface
                            conversation={conversationToDisplay}
                            setLoading={setLoading}
                            loading={loading}
                            setAccountStateFlow={setAccountStateFlow}
                            textToSpeech={textToSpeech}
                            answers={answers}
                            setAnswers={setAnswers}
                            IssuedId={IssuedId}
                            setIssuedId={setIssuedId}
                            accountStateFlow={accountStateFlow}
                            imageUrl={image}
                            token={auth_token}
                            videoCall={startVideoCall} />}
                        {response_type === 'quiz' && content && <QuizPage module={content} onComplete={setQuizResult}/>}
                        {/* <p style={{ fontSize: '0.8em', color: '#ffffff7d', textAlign: 'center' }}>LISA puede mostrar información inexacta que no representa las opiniones de AFP Crecer.</p> */}
                        { (qResult || response_type === 'lesson') && <Button sx={{color: 'gray', borderRadius:'25px', borderWidth:'1px', borderStyle:'solid'}} onClick={()=>forwardLesson(null)}> {'Ir a la siguiente lección'}</Button>}
                    </Stack>
                    {(responseType==='lesson' && nextLesson && endAudio===true) && <ProgressBar/>}
                    <Backdrop
                        sx={{ color: 'White', backgroundColor: '#ffffffa0', zIndex: 1500, padding: '20px', backdropFilter: 'blur(5px)' }}
                        open={listeningOn}>
                    </Backdrop>
                </Backdrop>

            </React.Fragment>
        )
    } else if (joined) {
        return (
            <div className="player-container" style={{ 'zIndex': 10000 }}>
                <Room
                    token={token}
                    roomName='Lisa-room'
                    onDisconnected={() => disconnected()}
                    errorHandle={() => errorHandle()}
                />
            </div >
        )
    }



    if (!joined) {
        return (
            <Backdrop
                sx={{ color: 'White', backgroundColor: '#ffffffa0', zIndex: 1500, padding: '20px', backdropFilter: 'blur(5px)' }}
                open={listeningOn}>
            </Backdrop>
        )
    }




    function disconnected() {
        setJoined(false)
        updateSate({ 'video': '' })
        updateSate({ 'video': 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/video-consulta/Intro_Afiliados_1.0.mp4' })
    }
    function errorHandle() {
        setJoined(false)
        setVoiceMessage(false)
        pauseAudio()
        updateSate({ 'video': '' })
        updateSate({ 'video': 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/GPT3/servicio%20al%20cliente%20no%20disponible_x264.mp4' })
    }

    function startVideoCall() {
        getToken(sessionId)
        setJoined(true)
    }

    function pauseAudio() {
        if (sound) {
            sound.pause()
            setPlaying(false)
        }
    }
    function playAudio() {
        if (sound) {
            sound.play()
            setPlaying(true)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function reproduceAudio(link) {
        setSound(link)
    }

    function closeMessageInterface() {
        setVoiceMessage(false)
        pauseAudio()
        // close()
    }

}
export default Dictaphone