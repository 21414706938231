
const Intro = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/2%20Si_x264.mp4'
const Excelente = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/Excelente!;%20este%20es%20el%20ltimo%20paso..._x264.mp4'
const QueQuieresHacer = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/Inicio%20-%20Qu%20quieres%20hacer__x264.mp4'
const Yatengounacuenta = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/4%20Ya%20tengo%20una%20cuenta_x264.mp4'
const registro_6_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/6.1%20-%20Registro_x264.mp4'
const registro_6_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/6.2%20-%20Registro_x264.mp4'
const registro_6_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/6.3%20-%20Registro_x264.mp4'
const registro_6_4 ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/6.4%20-%20Registro_x264.mp4'
const No = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/3%20No_x264.mp4'
const Mod_1_1 ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%201_%20Quines%20somos%20-%20Leccin%201_%20Introduccin%20al%20Grupo%20Rizek,%20cultura%20Corporativa%20y%20Organizacional_x264.mp4'
const Mod_1_2 ='https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%201_%20Quines%20somos%20-%20Leccin%202_%20AFP%20Crecer%20y%20Seguros%20Crecer_%20Evolucin%20y%20Misin_x264.mp4'
const Mod_1_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%201_%20Quines%20somos%20-%20Leccin%203_%20Rumbo%20a%20la%20Excelencia_%20Nuestro%20Programa%20de%20Desempeo_x264.mp4'
const Mod_1_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%201_%20Quines%20somos%20-%20Leccin%204_%20Responsabilidades%20y%20Cultura%20de%20Imagen%20Profesional%20en%20Crecer_x264.mp4'
const Mod_1_5 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%201_%20Quines%20somos%20-%20Leccin%205_%20Beneficios%20en%20Crecer_x264.mp4'
const Mod_2_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%202_%20Cdigo%20de%20tica%20%20Leccin%201_%20Principios%20ticos%20en%20AFP%20Crecer_x264.mp4'
const Mod_2_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%202_%20Cdigo%20de%20tica%20%20-%20%20Leccin%202_%20Cumplimiento%20y%20Legalidad_x264.mp4'
const Mod_2_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%202_%20Cdigo%20de%20tica%20%20-%20%20%20Leccin%203_%20Sea%20Honesto%20e%20ntegro_x264.mp4'
const Mod_2_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%202_%20Cdigo%20de%20tica%20%20-%20%20%20Leccin%204_%20Sea%20comprometido%20y%20dedicado_x264.mp4'
const Mod_2_5 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%202_%20Cdigo%20de%20tica%20%20-%20%20Leccin%205_%20Prevenga%20los%20Conflictos%20de%20Inters_x264.mp4'
const Mod_2_6 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%202_%20Cdigo%20de%20tica%20%20-%20Leccin%206_%20Deber%20de%20Proteccin%20de%20la%20Informacin_x264.mp4'
const Mod_2_7 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%202_%20Cdigo%20de%20tica%20%20-%20Leccin%207_%20Responsabilidad%20Social_x264.mp4'
const Mod_2_8 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%202_%20Cdigo%20de%20tica%20-%20Leccin%208_%20Canales%20de%20Informacin%20y%20Consecuencias%20por%20Incumplimientos%20en%20una%20Empresa_x264.mp4'
const Mod_3_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%203_%20Procesos%20%20Leccin%201_%20Introduccin%20a%20los%20Procesos%20y%20Organizacin%20del%20Equipo_x264.mp4'
const Mod_3_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%203_%20Procesos%20%20Leccin%202_%20Principios%20de%20la%20Gestin%20de%20Procesos_x264.mp4'
const Mod_3_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%203_%20Procesos%20-%20Leccin%203_%20Beneficios%20y%20Efectividad%20de%20la%20Gestin%20de%20Procesos_x264.mp4'
const Mod_3_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%203_%20Procesos%20%20Leccin%204_%20Aplicacin%20de%20la%20Gestin%20de%20Procesos%20y%20Expectativas%20del%20Equipo_x264.mp4'
const Mod_4_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%204_%20Riesgo%20Operativo%20%20Leccin%201_%20Marco%20Legal%20y%20Gestin%20de%20Riesgo_x264.mp4'
const Mod_4_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%204_%20Riesgo%20Operativo%20%20Leccin%202_%20Estructura%20de%20la%20Gestin%20de%20Riesgo_x264.mp4'
const Mod_4_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%204_%20Riesgo%20Operativo%20%20Leccin%203_%20Identificacin%20y%20Tratamiento%20de%20Riesgos_x264.mp4'
const Mod_4_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%204_%20Riesgo%20Operativo%20%20Leccin%204_%20Evaluacin%20de%20Riesgos%20y%20Continuidad%20de%20Negocios_x264.mp4'
const Mod_5_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%205_%20Legal%20y%20Cumplimiento%20%20Leccin%201_%20Introduccin%20al%20Cumplimiento%20y%20su%20Importancia_x264.mp4'
const Mod_5_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%205_%20Legal%20y%20Cumplimiento%20%20Leccin%202_%20Prevencin%20del%20Lavado%20de%20Activos%20y%20Financiamiento%20del%20Terrorismo_x264.mp4'
const Mod_5_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%205_%20Legal%20y%20Cumplimiento%20%20Leccin%203_%20Lneas%20de%20Defensa%20en%20Seguros%20Crecer%20y%20Creciendo%20Seguros_x264.mp4'
const Mod_5_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%205_%20Legal%20y%20Cumplimiento%20%20Leccin%204_%20Debida%20Diligencia%20y%20el%20Equipo%20de%20Cumplimiento_x264.mp4'
const Mod_6_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%206_%20Seguridad%20de%20la%20Informacin%20y%20Ciberseguridad%20%20Leccin%201_%20Importancia%20de%20las%20Polticas%20y%20Procedimientos%20de%20Seguridad_x264.mp4'
const Mod_6_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%206_%20Seguridad%20de%20la%20Informacin%20y%20Ciberseguridad%20%20Leccin%202_%20Gestin%20de%20Contraseas_x264.mp4'
const Mod_6_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%206_%20Seguridad%20de%20la%20Informacin%20y%20Ciberseguridad%20%20Leccin%203_%20El%20Empleado%20como%20Principal%20Activo%20de%20Seguridad_x264.mp4'
const Mod_6_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%206_%20Seguridad%20de%20la%20Informacin%20y%20Ciberseguridad%20%20Leccin%204_%20Evitando%20el%20Phishing%20y%20Otros%20Ataques%20Cibernticos_x264.mp4'
const Mod_7_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%207_%20Seguridad%20y%20Salud%20Ocupacional%20%20Leccin%201_%20Introduccin%20a%20la%20Seguridad%20y%20Salud%20Ocupacional%20en%20Crecer_x264.mp4'
const Mod_7_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%207_%20Seguridad%20y%20Salud%20Ocupacional%20%20Leccin%202_%20Implementacin%20y%20Gestin%20de%20la%20Seguridad%20y%20Salud%20Ocupacional%20en%20Crecer_x264.mp4'
const Mod_7_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%207_%20Seguridad%20y%20Salud%20Ocupacional%20%20Leccin%203_%20Conformacin%20y%20Responsabilidades%20de%20los%20Comits%20de%20Seguridad_x264.mp4'
const Mod_7_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%207_%20Seguridad%20y%20Salud%20Ocupacional%20%20Leccin%204_%20Organizacin,%20Normas%20de%20Salud%20y%20Seguridad,%20y%20Responsabilidades%20del%20Personal_x264.mp4'
const Mod_8_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%208_%20Experiencia%20del%20Cliente%20%20Leccin%201_%20Visin%20y%20Valores%20de%20la%20Empresa_x264.mp4'
const Mod_8_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%208_%20Experiencia%20del%20Cliente%20%20Leccin%202_%20La%20Importancia%20de%20una%20Comunicacin%20Responsable_x264.mp4'
const Mod_8_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%208_%20Experiencia%20del%20Cliente%20%20Leccin%203_%20Valor%20Agregado%20y%20Servicios%20Adicionales_x264.mp4'
const Mod_8_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%208_%20Experiencia%20del%20Cliente%20%20Leccin%204_%20Actividades%20y%20Beneficios%20Exclusivos_x264.mp4'
const Mod_9_1 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%209_%20Innovacin%20%20Leccin%201_%20Introduccin%20a%20la%20Innovacin%20y%20el%20Potencial%20de%20las%20Ideas_x264.mp4'
const Mod_9_2 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%209_%20Innovacin%20%20Leccin%202_%20La%20Gestin%20y%20Clasificacin%20de%20Ideas_x264.mp4'
const Mod_9_3 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%209_%20Innovacin%20%20Leccin%203_%20Proceso%20de%20Seleccin%20y%20Implementacin%20de%20Ideas_x264.mp4'
const Mod_9_4 = 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/videos/cursos/induccion-corporativa-crecer/Mdulo%209_%20Innovacin%20%20Leccin%204_%20El%20Impacto%20y%20la%20Sorpresa%20en%20la%20Innovacin_x264.mp4'
const Despedida = ''

export const nodes = [
    { id: 0,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 1,
                videoUrl: registro_6_1,
                text: 'Registrarme',
            },
            {
                type: 'node',
                videoUrl: Yatengounacuenta,
                text: 'Ya tengo una cuenta',
                nextNodeId: 6,
            }
        ],
        video: Intro
    },

    {
        id: 1,
        title: 'Digita tus datos:',
        inputs: [
            {
                type: 'text',
                text: 'Nombre',
                content: 'name'


            },
            {
                type: 'text',
                text: 'Apellido',
                content: 'lastName'

            },
            {
                type: 'text',
                text: 'Edad',
                content: 'age'
            }
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: registro_6_2,
                text: 'Siguiente',
                nextNodeId: 3,
            },
            {
                type: 'node',
                videoUrl: registro_6_1,
                text: 'Volver',
                nextNodeId: 0,
            }
        ],

    },
    {
        id: 2,
        title: '¿Cómo te puedo ayudar?',
        inputs: [
        ],
        buttons: [
            {
            },
        ],
    },
    {
        id: 3,
        title: 'Digita tus datos:',
        inputs: [
            {
                type: 'number',
                text: 'Número telefónico',
                content: 'cellphone'
            },
            {
                type: 'number',
                text: 'Cédula de identidad',
                content: 'id'


            },
            {
                type: 'text',
                text: 'Empresa',
                content: 'company'
            },


        ],
        buttons: [
            {
                type: 'node',
                videoUrl: registro_6_3,
                text: 'Siguiente',
                nextNodeId: 4,
            },
            {
                type: 'node',
                videoUrl: registro_6_2,
                text: 'Volver',
                nextNodeId: 1,
            }
        ],

    },
    {
        id: 4,
        title: 'Digita tus datos:',
        inputs: [
            {
                type: 'select',
                text: 'Nivel educativo',
                options: ['Primaria', 'Secundaria', 'Técnico', 'Universitario'],
                content: 'educationLevel'
            },
            {
                type: 'text',
                text: 'Posición',
                content: 'position'
            },
            {
                type: 'select',
                options: ['Técnico y detallado', 'Simple y fácil'],
                text: 'Tipo de Lenguaje',
                content: 'language'
            },
        ],
        buttons: [
            {
                type: 'node',
                videoUrl: registro_6_4,
                text: 'Siguiente',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: registro_6_3,
                text: 'Volver',
                nextNodeId: 3,
            }
        ],
    },
    {
        id: 5,
        title: 'Digita tus datos:',
        inputs: [
            {
                type: 'text',
                text: 'Correo electrónico',
                content: 'email',
            },
            {
                type: 'password',
                text: 'Contraseña',
                content: 'password',
            },
            {
                type: 'password',
                text: 'Confirmar Contraseña',
                content: 'confirmPassword',
            }
        ],
        buttons: [
            {
                type: 'send',
                text: 'Enviar',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: registro_6_4,
                text: 'Volver',
                nextNodeId: 3,
            }
        ],
    },
    {
        id: 6,
        title: 'Digita tus datos:',
        inputs: [
            {
                type: 'text',
                text: 'Correo electrónico',
                content: 'email',
            },
            {
                type: 'password',
                text: 'Contraseña',
                content: 'password',
            }
        ],
        buttons: [
            {
                type: 'authenticate',
                videoUrl: Excelente,
                text: 'Enviar',
                nextNodeId: 5,
            },
            {
                type: 'node',
                videoUrl: Intro,
                text: 'Volver',
                nextNodeId: 0,
            }
        ],
    },
    {
        id: 7,
        title: '¿Listo para iniciar?',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 1,
                videoUrl: registro_6_1,
                text: 'Si',
            },
            {
                type: 'node',
                videoUrl: No,
                text: 'No',
                nextNodeId: 0,
            }
        ],
        video: QueQuieresHacer
       
    },
    {
        id: 8,
        title: '¿Qué quieres hacer?',
        inputs: [],
        buttons: [
            {
                type: 'course',
                nextNodeId: 9,
                videoUrl: Mod_1_1,
                text: 'Tomar el curso de induccion',
            },
            {
                type: 'question',
                text: 'Hacer una pregunta',
                nextNodeId: 0,
            }
        ],
        video: QueQuieresHacer
       
    },
    {
        id: 9,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 10,
                videoUrl: Mod_1_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 8,
                videoUrl: Mod_1_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 10,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 11,
                videoUrl: Mod_1_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 9,
                videoUrl: Mod_1_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 11,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 12,
                videoUrl: Mod_1_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 10,
                videoUrl: Mod_1_3,
                text: 'Volver',
            }
        ],
    },
    {
        id: 12,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 13,
                videoUrl: Mod_1_5,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 11,
                videoUrl: Mod_1_4,
                text: 'Volver',
            }
        ],
    },
    // Módulo 2
    {
        id: 13,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 14,
                videoUrl: Mod_2_1,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 12,
                videoUrl: Mod_1_5,
                text: 'Volver',
            }
        ],
    },
    {
        id: 14,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 15,
                videoUrl: Mod_2_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 13,
                videoUrl: Mod_2_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 15,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 16,
                videoUrl: Mod_2_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 14,
                videoUrl: Mod_2_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 16,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 17,
                videoUrl: Mod_2_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 15,
                videoUrl: Mod_2_3,
                text: 'Volver',
            }
        ],
    },
    {
        id: 17,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 18,
                videoUrl: Mod_2_5,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 16,
                videoUrl: Mod_2_4,
                text: 'Volver',
            }
        ],
    },
    {
        id: 18,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 19,
                videoUrl: Mod_2_6,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 17,
                videoUrl: Mod_2_5,
                text: 'Volver',
            }
        ],
    },
    {
        id: 19,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 20,
                videoUrl: Mod_2_7,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 18,
                videoUrl: Mod_2_6,
                text: 'Volver',
            }
        ],
    },
    {
        id: 20,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 21,
                videoUrl: Mod_2_8,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 19,
                videoUrl: Mod_2_7,
                text: 'Volver',
            }
        ],
    },
    // Módulo 3
    {
        id: 21,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 22,
                videoUrl: Mod_3_1,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 20,
                videoUrl: Mod_2_8,
                text: 'Volver',
            }
        ],
    },
    {
        id: 22,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 23,
                videoUrl: Mod_3_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 21,
                videoUrl: Mod_3_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 23,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 24,
                videoUrl: Mod_3_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 22,
                videoUrl: Mod_3_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 24,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 25,
                videoUrl: Mod_3_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 23,
                videoUrl: Mod_3_3,
                text: 'Volver',
            }
        ],
    },
    // Módulo 4
    {
        id: 25,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 26,
                videoUrl: Mod_4_1,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 24,
                videoUrl: Mod_3_4,
                text: 'Volver',
            }
        ],
    },
    {
        id: 26,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 27,
                videoUrl: Mod_4_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 25,
                videoUrl: Mod_4_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 27,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 28,
                videoUrl: Mod_4_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 26,
                videoUrl: Mod_4_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 28,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 29,
                videoUrl: Mod_4_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 27,
                videoUrl: Mod_4_3,
                text: 'Volver',
            }
        ],
    },
    // Módulo 5
    {
        id: 29,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 30,
                videoUrl: Mod_5_1,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 28,
                videoUrl: Mod_4_4,
                text: 'Volver',
            }
        ],
    },
    {
        id: 30,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 31,
                videoUrl: Mod_5_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 29,
                videoUrl: Mod_5_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 31,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 32,
                videoUrl: Mod_5_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 30,
                videoUrl: Mod_5_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 32,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 33,
                videoUrl: Mod_5_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 31,
                videoUrl: Mod_5_3,
                text: 'Volver',
            }
        ],
    },
    // Módulo 6
    {
        id: 33,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 34,
                videoUrl: Mod_6_1,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 32,
                videoUrl: Mod_5_4,
                text: 'Volver',
            }
        ],
    },
    {
        id: 34,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 35,
                videoUrl: Mod_6_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 33,
                videoUrl: Mod_6_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 35,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 36,
                videoUrl: Mod_6_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 34,
                videoUrl: Mod_6_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 36,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 37,
                videoUrl: Mod_6_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 35,
                videoUrl: Mod_6_3,
                text: 'Volver',
            }
        ],
    },
    // Módulo 7
    {
        id: 37,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 38,
                videoUrl: Mod_7_1,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 36,
                videoUrl: Mod_6_4,
                text: 'Volver',
            }
        ],
    },
    {
        id: 38,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 39,
                videoUrl: Mod_7_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 37,
                videoUrl: Mod_7_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 39,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 40,
                videoUrl: Mod_7_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 38,
                videoUrl: Mod_7_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 40,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 41,
                videoUrl: Mod_7_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 39,
                videoUrl: Mod_7_3,
                text: 'Volver',
            }
        ],
    },
    // Módulo 8
    {
        id: 41,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 42,
                videoUrl: Mod_8_1,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 40,
                videoUrl: Mod_7_4,
                text: 'Volver',
            }
        ],
    },
    {
        id: 42,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 43,
                videoUrl: Mod_8_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 41,
                videoUrl: Mod_8_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 43,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 44,
                videoUrl: Mod_8_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 42,
                videoUrl: Mod_8_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 44,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 45,
                videoUrl: Mod_8_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 43,
                videoUrl: Mod_8_3,
                text: 'Volver',
            }
        ],
    },
    // Módulo 9
    {
        id: 45,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 46,
                videoUrl: Mod_9_1,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 44,
                videoUrl: Mod_8_4,
                text: 'Volver',
            }
        ],
    },
    {
        id: 46,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 47,
                videoUrl: Mod_9_2,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 45,
                videoUrl: Mod_9_1,
                text: 'Volver',
            }
        ],
    },
    {
        id: 47,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 48,
                videoUrl: Mod_9_3,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 46,
                videoUrl: Mod_9_2,
                text: 'Volver',
            }
        ],
    },
    {
        id: 48,
        title: '',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: 49,
                videoUrl: Mod_9_4,
                text: 'Siguiente',
            },
            {
                type: 'Node',
                nextNodeId: 47,
                videoUrl: Mod_9_3,
                text: 'Volver',
            }
        ],
    },
    // Video de Despedida
    {
        id: 49,
        title: 'Despedida',
        inputs: [],
        buttons: [
            {
                type: 'node',
                nextNodeId: null, // Esto puede variar dependiendo de lo que quieras hacer después de la despedida.
                videoUrl: Despedida,
                text: 'Finalizar',
            },
            {
                type: 'Node',
                nextNodeId: 48,
                videoUrl: Mod_9_4,
                text: 'Volver',
            }
        ],
    },
]