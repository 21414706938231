import React, {useState} from 'react';
import Popover from '@mui/material/Popover'
import { MicSharp, MicOffSharp,Phone } from '@mui/icons-material'
import Matraz from '../media/icons/matraz.png'
import '../css/iconsBar.css'

import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    LinkedinShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,

} from "react-share";


function IconsBar({ shareElement, setShare, switchMode, conversational, hasLoggetIn }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClose = () => {
        setShare(false);
    };

    React.useEffect(() => {
    }, [shareElement])

    const handleClickPhone = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClosePhone = () => {
        setAnchorEl(null);
      };
  
  
      React.useEffect(() => {
      }, [shareElement, anchorEl])
  
      const openPhone = Boolean(anchorEl);
      const idPhone = openPhone ? 'simple-popover' : undefined;

    const open = Boolean(shareElement);
    const id = open ? 'simple-popover' : undefined;
    const message = 'Conversa con Lisa en:';
    var shareUrl = 'https://afpcrecer.com.do/lisa'
    return (
        <React.Fragment>

            <Popover
                id={id}
                open={open}
                anchorEl={shareElement}
                onClose={handleClose}
                className='share-container'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <FacebookShareButton quote={message} url={shareUrl}>
                    <FacebookIcon round={true} />
                </FacebookShareButton>

                <EmailShareButton subject='Lisa - Afp Crecer' body={message} url={shareUrl}>
                    <EmailIcon className='share-icon' round={true} />
                </EmailShareButton>

                <WhatsappShareButton title={message} url={shareUrl}>
                    <WhatsappIcon className='share-icon' round={true} />
                </WhatsappShareButton>

                <LinkedinShareButton title={message} url={shareUrl}>
                    <LinkedinIcon className='share-icon' round={true} />
                </LinkedinShareButton>
            </Popover> 
            <div><Phone className='test-icon' onClick={(e) => handleClickPhone(e)} /></div>
            {/* {!conversational && hasLoggetIn && <MicSharp className='home' onClick={() => switchMode(true)} /> }
            {conversational && hasLoggetIn && <MicOffSharp className='home' onClick={() => switchMode(false)} />} */}

            <Popover
                id={idPhone}
                open={openPhone}
                anchorEl={anchorEl}
                onClose={handleClosePhone}
                className='share-container'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

            >
                <b>Atención al cliente</b>
                <hr/>
                <a href='tel:8096884040' ><Phone /> 809-688-4040 </a>

            </Popover>

        </React.Fragment>
    );
}

export default IconsBar