import '@fontsource/roboto/500.css'
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import '../css/optionsList.css'
import { nodes } from './nodes'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ReactGa from 'react-ga4';
import userRegistration from '../hooks/userRegistration';
import authenticate from '../hooks/authenticate';
import Cookies from 'universal-cookie'
import {FormControl, Select, InputLabel, MenuItem} from '@mui/material'

const DATE = new Date()
const COOKIES_EXPIRE = new Date('12-01-' + (DATE.getFullYear() + 1))
const DOMAIN = process.env.REACT_APP_DOMAIN

function ButtonsList ({close, switchMode, updateSate, actualNode, update, updateNode, domelement, setToken}) { 
    const [node, setNode] = useState(nodes[0])
    const [alert, setMessage] = useState("")
    const [email, setEmail] = useState()
    const [id, setId] = useState()
    const [cellphone, setCellphone] = useState()
    const [age, setAge] = useState()
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [name, setName] = useState()
    const [lastName, setLastName] = useState()
    const [company, setCompany] = useState()
    const [position, setPosition] = useState()
    const [role, setRole] = useState()
    const [notifications, setNotifications] = useState()
    const [userInfo, setUserInfo] = useState()
    const [educationLevel, setEducationLevel] = useState()
    const [language, setlanguage] = useState()

    const { isLoading,
            success,
            message,
            registrateUser } = userRegistration();

    const { authenticateUser,
            isAuthenticated,
            isLoadingAuth,
            token
            } = authenticate();    

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function login(){
        authenticateUser(email, password)
    }

    const takeCourse = () => {
        updateSate({ initialPrompt:'course'})
        updateSate({ isFirstPrompt: true})
        switchMode()
    }

    const askQuestion = () => {
        updateSate({ initialPrompt:'Deseo hacer una pregunta'})
        updateSate({ isFirstPrompt: true})
        switchMode()
    }

    function register(){
        // code to validate inputs before user registration
        function validateInputs(){
            let alert = domelement.querySelector('.alert-info')
            alert.setAttribute("hidden", true);
            alert.removeAttribute("hidden");
            if (password !== confirmPassword){ 
                setMessage("Las contraseñas no coinciden")
                alert.removeAttribute("hidden");
                return false
            }
            if (email === undefined || email === ""){
                setMessage("El correo electrónico es obligatorio")
                alert.removeAttribute("hidden");
                return false
            }
            if (password === undefined || password === ""){
                setMessage("La contraseña es obligatoria")
                alert.removeAttribute("hidden");
                return false
            }
            if (confirmPassword === undefined || confirmPassword === "" || confirmPassword !== password){
                setMessage("Las contraseña no coinciden")
                alert.removeAttribute("hidden");
                return false
            }
            if (name === undefined || name === ""){
                setMessage("El nombre es obligatorio")
                alert.removeAttribute("hidden");
                return false
            }
            if (lastName === undefined || lastName === ""){
                setMessage("El apellido es obligatorio")
                alert.removeAttribute("hidden");
                return false
            }
            if (cellphone === undefined || cellphone === ""){
                setMessage("El número de celular es obligatorio")
                alert.removeAttribute("hidden");
                return false
            }
            if (id === undefined || id === ""){
                setMessage("La cédula de identidad es obligatoria")
                alert.removeAttribute("hidden");
                return false
            }
            if (age === undefined || age === ""){
                setMessage("La edad es obligatoria")
                alert.removeAttribute("hidden");
                return false
            }
            if (company === undefined || company === ""){
                setMessage("La empresa es obligatoria")
                alert.removeAttribute("hidden");
                return false
            }
            if (position === undefined || position === ""){
                setMessage("El cargo es obligatorio")
                alert.removeAttribute("hidden");
                return false
            }
            // if (role === undefined || role === ""){
            //     setMessage("El rol es obligatorio")
            //     let alert = domelement.querySelector('.alert-info')
            //     alert.removeAttribute("hidden");
            //     return false
            // }
            // if (notifications === undefined || notifications === ""){
            //     setMessage("Las notificaciones son obligatorias")
            //     let alert = domelement.querySelector('.alert-info')
            //     alert.removeAttribute("hidden");
            //     return false
            // }
            if (educationLevel === undefined || educationLevel === ""){
                setMessage("El nivel de educación es obligatorio")
                alert.removeAttribute("hidden");
                return false
            }
            if (position === undefined || position === ""){
                setMessage("El cargo es obligatorio")
                alert.removeAttribute("hidden");
                return false
            }
            if (company === undefined || company === ""){  
                setMessage("La empresa es obligatoria")
                alert.removeAttribute("hidden");
                return false
            }
            return true
        }
        if (validateInputs()){
            registrateUser(name, lastName, email, cellphone, id, role, company, position, password, notifications, age, educationLevel, language)
        }
    }

    useEffect(() => {
        if (undefined !== updateNode && node.id !== updateNode.id && update === true) {
            actualNode(updateNode.id)
            setNode(updateNode)
        }

        if (success){
            setNode(nodes[6])
        }else if (message){
            setMessage(message)
            let alert = domelement.querySelector('.alert-info')
            alert.removeAttribute("hidden");
        }

        if (isAuthenticated){
            setToken(token)

            const cookies = new Cookies()
            cookies.set('token', token, {
                path: '/', expires: COOKIES_EXPIRE,
                sameSite: 'none',
                secure: true,
                httpOnly: false,
                domain: DOMAIN
            })
            setNode(nodes[8])
            updateSate({video: 'https://prototipos.nyc3.cdn.digitaloceanspaces.com/crecerlab-assistant/Inicio%20-%20Qu%20quieres%20hacer__x264.mp4'})
        }

    },[success, message, isAuthenticated, updateNode])


    function handleChange(event) {
        let content = event.target.getAttribute("id")
        let capitalizedContent = capitalizeFirstLetter(content)
        let alert = domelement.querySelector('.alert-info')
        let value = event.target.value;
        // eslint-disable-next-line no-eval
        let actualValue = eval(`${content}`);
        alert.setAttribute("hidden", true);
        if (event.target.getAttribute("type") === "email") {
            // eslint-disable-next-line
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var validate = re.test(value)
            setEmail(value)
            if (!validate) {
                setMessage( "No es una dirección de email válida ejemplo: ejemplo@ejemplo.com" )
                alert.removeAttribute("hidden")
                setEmail(null)
            }

        }
        else if (content === "id") {
            // eslint-disable-next-line
            const re = /[0-9]$/;
            validate = re.test(value);
            if (validate && value.length === 11) {
                setId(value);
            } else if (value.length > 11) {
                setId(id);
            } else {
                setId(value);
            }

            if (value.length < 11) {
                setMessage( "No es una Cédula válida, debe contener 11 digitos" );
                alert.removeAttribute("hidden");
            }


        } else if (content === "cellphone") {
            // eslint-disable-next-line
            const re = /[0-9]$/;
            validate = re.test(value);
            if (validate & value.length === 10) {
                setCellphone( value )
            } else if (value.length > 10) {
                setCellphone(actualValue)
            }

            if (value.length < 10) {
                setMessage( "El numero celular  debe contener al menos 10 digitos" );
                alert.removeAttribute("hidden");
                setCellphone(null)
            }

        }else if (content === "age") {
            // eslint-disable-next-line
            const re = /[0-9]$/;
            validate = re.test(value);
            if (validate ) {
                eval(`set${capitalizedContent}(value)`)
            } else if ( value === ""){
                eval(`set${capitalizedContent}(null)`)
            }else {
                eval(`set${capitalizedContent}(actualValue)`)
            }

        }  else if (content === "password" || content === "confirmPassword") {
             if ( value === ""){
                // eslint-disable-next-line no-eval
                eval(`set${capitalizedContent}(null)`);
            }else {
                // eslint-disable-next-line no-eval
                eval(`set${capitalizedContent}(value)`);
            }

        } else {
            // eslint-disable-next-line
            const re = /[A-Za-z ]$/; 
            validate = re.test(value);
            if (validate) {
                // eslint-disable-next-line no-eval
                eval(`set${capitalizedContent}(value)`);
  
            } else if (value === ""){
                // eslint-disable-next-line no-eval
                eval(`set${capitalizedContent}(null )`);
            }
            else{
                setMessage("No se admiten numeros o simbolos especiales" );
                alert.removeAttribute("hidden");
                if (actualValue){
                    // eslint-disable-next-line no-eval
                    eval(`set${capitalizedContent}( actualValue ? actualValue : '')`);
                }
                
            }
        }

    }

    function loadVideo(event) {
        updateSate({ 'video': event.target.getAttribute("video-url") });
    }

    function loadNode(event) {
        var nodeIndex = event.target.getAttribute("actual-id");
        var buttonIndex = event.target.getAttribute("button-index") - 1

        ReactGa.event({
            category: "Botones",
            action: nodes[nodeIndex].buttons[buttonIndex].text,
            label: nodes[nodeIndex].buttons[buttonIndex].text, // optional
            //value: 99, // optional, must be a number
            //nonInteraction: true, // optional, true/false
            // transport: "xhr", // optional, beacon/xhr/image
        });
        var alert = domelement.querySelector('.alert-info')
        alert.setAttribute("hidden", true)

        updateSate({ 'video': event.target.getAttribute("video-url") })
        var index = parseInt(event.target.getAttribute("node-id"))
        actualNode(index)
        setNode(nodes[index])
    }


        var buttons = [];               //initialize array to storage buttons elements for the view 
        var inputs = [];                //initialize array to storage inputs elements for the view 
        var x = 1;                      //counter for button options


        node.inputs.forEach(input => {
            if (input.type === "text") {
                inputs.push(<TextField autoComplete='false' className="outlined-basic" type={input.content} key={input.text} label={input.text} id={input.content} variant="filled" margin="dense" required onChange={(e)=>handleChange(e)} value={eval(`${input.content}`)}/>);
            }
            if (input.type === "number") {
                inputs.push(<TextField autoComplete='false' pattern="[0-9]*" style={{ borderColor: 'red', borderWidth: '3px' }} keyboardType='numeric' type='tel' id={input.content} className="outlined-basic" key={input.content} label={input.text} variant="filled" margin="dense" required onChange={(e)=>handleChange(e)} value={eval(`${input.content}`)}/>);
            }
            if (input.type === "password"){
                inputs.push(<TextField autoComplete='false' className="outlined-basic" type="password" key={input.text} label={input.text} id={input.content} variant="filled" margin="dense" required onChange={(e)=>handleChange(e)} value={eval(`${input.content}`) }/>);
            }
            if (input.type === "select"){
                inputs.push(<FormControl fullWidth>
                    <InputLabel id="select-labe" style={{color:'white'}}>{input.text}</InputLabel>
                        <Select
                            labelId="select-label"
                            id={input.text}
                            value={eval(`${input.content}`)}
                            label={input.text}
                            onChange={(e) => input.content==='educationLevel' ? setEducationLevel(e.target.value): setlanguage(e.target.value)}
                            style={{color: 'white'}}
                        >
                            {input.options.map((option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                </FormControl>)
                // inputs.push(<TextField autoComplete='false' className="outlined-basic" type={input.content} key={input.text} label={input.text} id={input.content} variant="filled" margin="dense" required onChange={(e)=>handleChange(e)} value={eval(`${input.content}`)}/>);
            }
        });

        node.buttons.forEach(button => {
            const text = <span className="inside-button"> <span className='num'> {x} </span ><span>{button.text}</span></span>;
            if (button.type === "video") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" video-url={button.videoUrl} onClick={()=>loadVideo()}> {text}</Button>);
            }
            if (button.type === "node") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} onClick={(e)=>loadNode(e)} button-index={x} actual-id={node.id}>{text} </Button>);
            }
            if (button.type === "send") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl}  onClick={()=>register()} button-index={x} actual-id={node.id}> {text}</Button>);
            }
            if (button.type === "authenticate") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl}  onClick={()=>login()} button-index={x} actual-id={node.id}> {text}</Button>);
            }
            if (button.type === "course") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl}  onClick={()=>takeCourse()} button-index={x} actual-id={node.id}> {text}</Button>);
            }
            if (button.type === "question") {
                buttons.push(<Button variant="outlined" key={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl}  onClick={()=>askQuestion()} button-index={x} actual-id={node.id}> {text}</Button>);
            }
            if (button.type === "close") {
                buttons.push(<Button variant="outlined" ref={button.text} className="buttons" node-id={button.nextNodeId} video-url={button.videoUrl} onClick={()=> close()} button-index={x} actual-id={node.id}>{text}</Button>);
            }

            x++;
        });

        return (
            <div className="list" hidden>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 100, pointerEvents: "auto" }}
                    open={isLoading || isLoadingAuth}
                >
                    <CircularProgress color="inherit" />

                </Backdrop>
                <Alert severity="info" className="alert-info" hidden >{alert}</Alert>
                <h1 className="title" >{node.title}</h1>
                <div className="buttons-list controls-cta">
                    <Stack>
                        {inputs}
                        {buttons}
                    </Stack>
                </div>
            </div>
        )

}

export default ButtonsList;