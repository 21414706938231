
import '../css/messageInterface.css'
import 'react-chat-elements/dist/main.css'
import { Fab, Popover, Backdrop, Card, Chip, TextField, Button } from '@mui/material';
import ThumbUp from '@mui/icons-material/ThumbUpAltRounded'
import ThumbDown from '@mui/icons-material/ThumbDownRounded'
import Export from '@mui/icons-material/ArchiveOutlined'
import React, { useCallback, useState } from 'react';
import { MessageList } from 'react-chat-elements'
import { useEffect } from 'react';
import PromptFeedback from '../hooks/promptFeedback';
import { AccountStateFlow } from './accountStateFlow'
import parse from 'html-react-parser'
import {
    WhatsappShareButton,
    EmailShareButton,
} from "react-share";


let messageListReferance = React.createRef();

function MessageInterface({ conversation, imageUrl,loading}) {

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [displayVideoCard, setDisplayVideoCard] = React.useState(false)
    const [collectInfo, setCollectInfo] = React.useState()
    const [additionalInfo, setAditionalInfo] = React.useState('')
    const [tagInfo, setTagInfo] = React.useState([])
    const [disable, setDisable] = React.useState(true)
    const [feedbackData, setFeedbackData] = useState([
        { name: 'positivo', icon: <ThumbUp className='feedback-icons' />, class: 'default', checked: false },
        { name: 'negativo', icon: <ThumbDown className='feedback-icons' />, class: 'default', checked: false },
    ])
    const [chipData, setChipData] = React.useState([
        { key: 0, label: 'Ofensivo/Inseguro', color: 'default', disabled: false, icon: '' },
        { key: 1, label: 'Irrelevante', color: 'default', disabled: false, icon: '' },
        { key: 2, label: 'No es objetivamente correcto', color: 'default', disabled: false, icon: '' },
    ])

    const { promptFeedback } = PromptFeedback()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleSelection = (data) => () => {
    //     const chips = chipData
    //     if (chips[data.key]['color'] === 'primary') {
    //         chips[data.key]['color'] = 'default'
    //     } else {
    //         chips[data.key]['color'] = 'primary'
    //     }
    //     let tagSelected = chips.filter((chip) => chip.color === 'primary')
    //     var tagLabels = []
    //     tagSelected.forEach(chip => {
    //         tagLabels.push(
    //             chip.label
    //         )
    //     })
    //     setTagInfo(tagLabels)

    // }

    const  formatConversation = useCallback ((data, imageUrl) => {
        let conversation = []

        if (imageUrl) {
            conversation.push({
                position: 'left',
                type: 'photo',
                data: {
                    uri: imageUrl,
                }
            })
        }

        if (data[data.length - 2] !== null) {
            conversation.push({
                position: 'left',
                type: 'text',
                text: <p>{data[data.length - 2]['content']}</p>,
            })
        }
        if (data[data.length - 1] !== null) {
            conversation.push({
                position: 'right',
                type: 'text',
                text: <div>{parse(data[data.length - 1]['content'])}</div>,
            })
        }

        return conversation
    },[])

    // const handleCloseAdditionalInfo = () => {
    //     promptFeedback(messages[0].text, messages[1].text, 'negativo', additionalInfo, tagInfo, token)
    //     setCollectInfo(false)
    // }


    let messages = formatConversation(conversation, imageUrl)
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    let image = ''
    if (imageUrl) {
        image = <img src={imageUrl} alt='repsonse' className='response-image' />
    }

    // function handleFeedbackClick(event) {
    //     let name = event.currentTarget.getAttribute('name')
    //     const feedback = feedbackData
    //     let data = []

    //     feedback.forEach((button) => {
    //         if (button.name === name && ['positivo', 'negativo'].includes(name)) {

    //             if (name === 'positivo' && feedback[1].checked === false && !button.checked) {
    //                 button.class = 'selected'
    //                 button.checked = true
    //                 promptFeedback(messages[0].text, messages[1].text, name, additionalInfo, tagInfo, token)
    //             }
    //             if (name === 'negativo' && feedback[0].checked === false && !button.checked) {
    //                 button.class = 'selected'
    //                 button.checked = true
    //                 setCollectInfo(true)

    //             }
    //         }

    //         data.push(button)
    //     })
    //     setFeedbackData(data)
    // }

    // function collectAditionalInformation(feedback) {
    //     promptFeedback(messages[0].text, messages[1].text, feedback, additionalInfo, tagInfo, token)
    //     setCollectInfo(false)

    // }

    let buttonsComponents = ''
    // if (accountStateFlow) {
    //     buttonsComponents = <AccountStateFlow
    //         cedula={cedula}
    //         setCedula={setCedula}
    //         setConversation={setConversation}
    //         conversation={conversation}
    //         setLoading={setLoading}
    //         loading={loading}
    //         setAccountStateFlow={setAccountStateFlow}
    //         textToSpeech={textToSpeech}
    //         answers={answers}
    //         setAnswers={setAnswers}
    //         IssuedId={IssuedId}
    //         setIssuedId={setIssuedId} />
    // }

    const exportResponse = <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className='share-options'
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
    >
        <EmailShareButton subject='Lisa - Afp Crecer' body={messages[0].text?.props?.children + '\n' + messages[1].text?.props?.children} >
            <p>Email</p>
        </EmailShareButton>

        <WhatsappShareButton title={ messages[0].text?.props?.children + ' \n respuesta:' + messages[1].text?.props?.children} url='https://cursos.crecerlab.com' separator='  '>
            <p>Whatsapp</p>
        </WhatsappShareButton>

    </Popover>

     /* const card = <Card sx={{ zIndex: 1000, padding: '20px 20px', alignItems: 'center', display: 'flex', flexDirection: 'column', maxWidth: '800px' }}>
       <p>¿Por que elegiste esta valoración?</p>
        <div>
            {chipData.map((data) => {
                return (
                    <Chip
                        sx={{ margin: '2px' }}
                        className='chips'
                        variant="outlined"
                        deleteIcon={data.icon}
                        label={data.label}
                        color={data.color}
                        onClick={handleSelection(data)}
                        onDelete={handleSelection(data)}
                        disabled={data.disabled}
                    />

                )
            })}
        </div>
        <TextField label="Información adicional" onChange={(e) => setAditionalInfo(e.currentTarget.value)} color="primary" multiline={true} rows="5" sx={{ width: '100%', marginTop: '20px', backgroundColor: 'white' }} />
        <Button onClick={() => collectAditionalInformation('negativo')} disabled={disable} sx={{
            marginTop: '20px'
        }}>Enviar</Button>
    </Card> */

    /* const videoCard = <div style={{ padding: '6%' }}>
        <h3>Video llamada</h3>
        <div style={{ marginTop: '20px', maxHeight: '38vh', overflow: 'scroll' }}>
            <p>{'Al hacer click en aceptar te conectaremos con un oficial de servicio al cliente mediante una video llamada'}</p>
        </div>
        <Button style={{ marginTop: '20px' }} onClick={() => videoCall()}>Aceptar</Button>
        <Button style={{ marginTop: '20px', float: 'right' }} onClick={() => setDisplayVideoCard(false)}>Cancelar</Button>
    </div>
    useEffect(() => {
        if (tagInfo.length > 0 || additionalInfo) {
            setDisable(false)
        } else {
            setDisable(true)
        }
    }, [feedbackData, collectInfo, additionalInfo, tagInfo, accountStateFlow, loading, cedula, IssuedId]) */



    return (
        <React.Fragment>
            <Backdrop
                style={{ zIndex: 2000 }}
                open={displayVideoCard}>
                <Chip className="close-terms" label={<span>X</span >} variant="outlined" onClick={() => setDisplayVideoCard(false)} style={{ zIndex: 10000 }}> </Chip>
                <Card className='terms' style={{ marginTop: '30%', maxHeight: '65%', width: '90%', maxWidth: '800px' }}
                    variant="outlined">
                    {/* {videoCard} */}
                </Card>
            </Backdrop>
            {/* <Backdrop
                style={{ zIndex: 10000, alignItems: 'center', display: 'flex' }}
                open={collectInfo}>
                <Chip className="close-terms" label={<span>X</span >} variant="outlined" onClick={() => handleCloseAdditionalInfo()} style={{ zIndex: 1 }}> </Chip>
                <Card className='feedback-dash' style={{ marginTop: '10%', width: '90%', padding: '5px 5px', backgroundColor: 'transparent' }}
                    variant="outlined">
                     {card} 
                 </Card> 
            </Backdrop> */}

            {/* <div className='gradients' /> */}
            {exportResponse}
            <div style={{ paddingTop: '40px' }}>
                <MessageList
                    downButton={true}
                    referance={messageListReferance}
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={messages} />
                {buttonsComponents}
                {/* <div className='feedback-buttons'>
                    <div>
                        {feedbackData.map((data) => {
                            return (<React.Fragment>
                                <Fab aria-label="like" key={data.name} className={`feedback-fab ${data.class}`} name={data.name} onClick={(e) => handleFeedbackClick(e)}>
                                    {data.icon}{data.animation}
                                </Fab>
                            </React.Fragment>
                            );
                        })}
                    </div> 
                    <Fab aria-label="export" className='feedback-fab' onClick={(e) => handleClick(e)}>
                        <Export className='feedback-icons' />
                    </Fab>
                    <Button aria-label="video-call" className='call-button' onClick={() => setDisplayVideoCard(true)} >
                         <Typography sx={{ fontSize: '10px' }}>Llamar un operador</Typography> 
                    </Button> 

                </div> */}
            </div>

        </React.Fragment>
    )


}

export default MessageInterface