/* eslint-disable react-hooks/exhaustive-deps */
import '@fontsource/roboto/500.css'
import '../css/login.css'
import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import { Stack } from '@mui/material'
import { Button } from '@mui/material'
import { Alert, Backdrop, CircularProgress } from '@mui/material'
import { SignUpForm } from './SignUpForm'
import { ArrowBack, VisibilityOff, Visibility, Domain } from '@mui/icons-material'
import Authenticate from '../hooks/authenticate'
import { FormControlLabel } from '@mui/material'
import { FormControl, InputLabel, OutlinedInput, Chip } from '@mui/material'
import { Checkbox, InputAdornment, IconButton, Card } from '@mui/material'
import Logo from '../media/icons/lab-logo.png'
import Cookies from 'universal-cookie'
import PasswordReset from './PasswordReset'

const DATE = new Date()
const COOKIES_EXPIRE = new Date('12-01-' + (DATE.getFullYear() + 1))
export function UserLogin({ setToken, setId, backButton, loadNode }) {


    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [alert, setAlert] = useState()
    const [register, setRegister] = useState()
    const [keepLogin, setKeepLogin] = useState()
    const [passwordReset, setPasswordReset] = useState()
    const [message, setMessage] = useState()
    const [displayMessage, setDisplayMessage] = useState()
    const [onboard, setOnboard] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const DOMAIN = process.env.REACT_APP_DOMAIN

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickKeepLogin = (value) => {
        if (value) {
            setKeepLogin(true)
        }
    }

    const {
        error,
        isLoading,
        isAuthenticated,
        success,
        id,
        token,
        authenticateUser,
    } = Authenticate()

    useEffect(() => {
        if (isAuthenticated) {
            if (keepLogin) {
                const cookies = new Cookies()
                cookies.set('token', token, {
                    path: '/', expires: COOKIES_EXPIRE,
                    sameSite: 'none',
                    secure: true,
                    httpOnly: false,
                    domain: DOMAIN
                })
            }

            setToken(token)
            setId(id)

        } else if (success) {
            displayAlert('Usuario o Contraseña Incorrectos', 'error')
        }

        if (error) {
            displayAlert(error, 'error')

        }

        if (message) {
            setDisplayMessage(true)
        }

    }, [setToken, isAuthenticated, success, token, passwordReset, setPasswordReset, message, displayMessage, setDisplayMessage])

    if (register) {
        return (
            <SignUpForm setRegister={setRegister} setMessage={setMessage} loadNode={loadNode} switchMode={backButton} />)
    }

    if (passwordReset) {
        return (
            <React.Fragment>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 500 }}
                    open={loading}
                >
                    <CircularProgress sx={{ color: "white", fontSize: 80 }} />
                </Backdrop>
                {alert}
                <Button className="back-btn" variant="contained" onClick={() => setPasswordReset(false)}> <ArrowBack /> Regresar</Button>
                <PasswordReset closeView={setPasswordReset} displayAlert={displayAlert} setLoading={setLoading} />
            </React.Fragment>
        )

    }


    function handleDisplayMessage() {
        setDisplayMessage(false)
        setMessage(false)
    }
    const card = <div style={{ padding: '6%' }}>
        <h3>Registro Exitoso!</h3>
        <div style={{ marginTop: '20px', maxHeight: '38vh', overflow: 'scroll' }}>
            <p>{message}</p>
        </div>
        <Button style={{ marginTop: '20px' }} onClick={() => handleDisplayMessage()}>Aceptar</Button>
    </div>


    if (onboard) {
        return (
            <React.Fragment>
                {/* <Button className="back-btn" variant="contained" onClick={() => back()}> <ArrowBack /> Regresar</Button> */}
                <Stack className="form-login" spacing={2}>
                    <div style={{ alignItems: 'center', textAlign: 'center' }}>
                        <h1 style={{ 'color': 'white', fontSize: '5em', marginBottom: '-20px' }}><b>LISA</b></h1>
                        <p>Respuestas Precisas</p><br /><br />

                        <p><b>Una experiencia</b> <br />
                            Simple, humana y personalizada<br /><br /><br />
                        </p>

                        <p><b>Pregúntale<br /></b>
                            Lo que quieras<br />
                            Como quieras<br />
                            Cuando quieras</p><br /><br />
                    </div>
                    <Button className="login-btn" variant="contained" onClick={() => setRegister(true)} >Registrarme</Button>
                    <Button className="register-btn" variant="contained" onClick={() => setOnboard(false)}>Ya tengo una cuenta </Button>

                </Stack>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Backdrop
                style={{ zIndex: 10 }}
                open={displayMessage}>
                <Chip className="close-terms" label={<span>X</span >} variant="outlined" onClick={() => handleDisplayMessage()} style={{ zIndex: 10000 }}> </Chip>
                <Card className='terms' style={{ marginTop: '30%', maxHeight: '65%', width: '90%' }}
                    variant="outlined">
                    {card}
                </Card>
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: 500 }}
                open={isLoading}
            >
                <CircularProgress sx={{ color: "white", fontSize: 80 }} />
            </Backdrop>
            {alert}
            {/* <Button className="back-btn" variant="contained" onClick={() => back()}> <ArrowBack /> Regresar</Button> */}
            <Stack className="form-login" spacing={2}>
                <img src={Logo} className='form-logo' alt='AFP crecer'
                    style={{
                        position: 'relative', maxWidth: '300px',
                        zIndex: 11, mixBlendMode: 'luminosity',
                        marginBottom: '30px'
                    }}></img>
                <FormControl sx={{ m: 1}} variant="outlined">
                    <TextField label="Email" onChange={e => setEmail(e.target.value)} />
                </FormControl>
                <FormControl sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password" className='pass-label'>Contraseña</InputLabel>
                    <OutlinedInput
                        onChange={e => setPassword(e.target.value)}
                        id="password"
                        className='password'
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Contraseña"
                    />
                </FormControl>
                <Button className="login-btn" variant="contained" onClick={() => login()}>Acceder</Button>
                <Button className="register-btn" variant="contained" onClick={() => setRegister(true)} >Registrarme</Button>
                <FormControlLabel
                    required control={
                        <Checkbox className='check'
                            checked={keepLogin}
                            onChange={(e) => handleClickKeepLogin(e.target.checked)
                            } />}
                    label="Mantener la sesión iniciada en este dispositivo" />
                <div >
                    <p >No puedo ingresar <span onClick={() => setPasswordReset(true)} style={{ cursor: 'pointer' }}>olvidé mi contraseña</span></p>
                </div>
            </Stack>
        </React.Fragment>
    )

    function login() {
        authenticateUser(email, password)
    }

    function back() {
        backButton()
    }

    function displayAlert(message, type) {
        if (type === 'error') {

            setAlert(<Alert className='alert' severity="error">{message}</Alert>);
            setTimeout(() => {
                setAlert()
            }, 3000)

        } else {

            setAlert(<Alert className='alert' severity="success">{message}</Alert>);
            setTimeout(() => {
                setAlert()
            }, 3000)

        }

    }

}



