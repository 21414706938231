import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export default function ProgressBar() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        // if (oldProgress === 100) {
        //   return 0;
        // }
        return Math.min(oldProgress + 5, 100);
      });
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
      <LinearProgress variant="determinate" value={progress} style={{zIndex:100, position:'absolute', width:'100%', marginTop: '-20px', height:'10px'}}/>
  );
}