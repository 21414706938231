import React, {Component} from 'react';
import Quiz from 'react-quiz-component';
import {MDBContainer, MDBRow, MDBCol} from 'mdbreact';


class QuizPage extends Component {
  render() {
    return (
      <div  style={{overflow:"scroll", maxHeight: '70%'}}>
        <MDBContainer>
          <MDBRow middle='true'>
            <MDBCol>
              <Quiz quiz={this.props.module} shuffle={true} onComplete={this.props.onComplete} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    )
  }
}

export default QuizPage;