import { useState } from 'react'

const API_GET_LESSON = process.env.REACT_APP_AI_API_URL + '/api/v1/lesson/'
const DATA = {
  success: false,
  isLoading: false,
  content: false,
  audioUrl: false,
  lessonNumber: false,
  moduleNumber: false,
  moduleTitle: false,
  lessonTitle: false,
  lessonSummary: false,
}

/**
 * Get user info
 */
export default function UseLessons() {
  const [data, setData] = useState(DATA)
  const getNextLesson = async (auth_token, lesson_info) => {
    const requestInfo = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': auth_token
      },
      body: JSON.stringify(lesson_info)
    };
    setData({ ...DATA, isLoading: true })
    await fetch(`${API_GET_LESSON}`, requestInfo)
      .then(res => res.json())
      .then(result => {
        if (result.content) {
          setData({
            success: true,
            content: result.content,
            audioUrl: result.audio_url,
            lessonNumber: result.lesson_number,
            moduleNumber: result.module_number,
            moduleTitle: result.module_title,
            lessonTitle : result.lesson_title,
            lessonSummary: result.lesson_summary,
            response_type: result.response_type,
            isLoading: false
          })
        } else {
          setData({
            success: result.success
          })
        }
      })
      .catch(error => {
        setData(c => ({ ...c, error }))
      })
  }
  return { ...data, getNextLesson }
}