import '../css/App.css';
import '../css/accountState.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AccountState from './accountState';
import DynamicVideo from './dynamicVideo';
import { datadogRum } from '@datadog/browser-rum';

document.addEventListener('contextmenu', event => event.preventDefault());

export function App() {

    // const [token, setToken] = useState()
    // if (!token) {
    //     return (
    //         <Login setToken={setToken} />
    //     );
    // }
    // else {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<DynamicVideo />} />
                    <Route path="/estadodecuenta/:id" element={<AccountState />} />
                </Routes>
            </BrowserRouter>
        </React.Fragment>
    );

    // }
}

if (process.env.REACT_APP_ENV !== 'development') {
    datadogRum.init({
        applicationId: 'f3f0213f-729e-43ad-be76-64149843dd71',
        clientToken: 'pub48a1546464136811991fde5e364cda68',
        site: 'datadoghq.com',
        service: 'lisa-frontend',
        env: process.env.REACT_APP_ENV,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording();
};

